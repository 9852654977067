import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import Contacts from './Contacts';
import Menu from './Menu';
import styles from './Sidebar.module.scss';

export const PureSidebar = ({ data, isIndex }) => {
  const contacts = {
    twitter: 'bkrausz',
    email: 'hello@startupjoboffers.com',
  };
  const { title, subtitle, menu } = data.site.siteMetadata;

  let titleLink = <Link className={styles['about__title-link']} to="/">{title}</Link>;
  if (isIndex) {
    titleLink = <h1 className={styles['about__title']}>{titleLink}</h1>;
  } else {
    titleLink = <h2 className={styles['about__title']}>{titleLink}</h2>;
  }

  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar__inner']}>
        <div className={styles['about']}>
          {titleLink}
          <p className={styles['about__subtitle']}>{subtitle}</p>
        </div>

        <Menu menu={menu} />
        <Contacts contacts={contacts} />
        <div className={styles['sidebar__inner__copyright']}>
          © {new Date().getFullYear()} &bull; For informational purposes only,
          not legal or financial advice &bull;{' '}
          <a href="/pages/disclaimer-privacy/">Disclaimer & Privacy</a>
        </div>
      </div>
    </div>
  );
};

export const Sidebar = (props) => (
  <StaticQuery
    query={graphql`
      query SidebarQuery {
        site {
          siteMetadata {
            title
            subtitle
            menu {
              label
              path
            }
          }
        }
      }
    `}
    render={(data) => <PureSidebar {...props} data={data} />}
  />
);

export default Sidebar;
